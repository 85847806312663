<template>
  <div class="row d-flex justify-center align-center">
    <v-card class="tgc-header-card w-88 p-3" color="primary" dark>
      <slot></slot>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>
<style lang="sass">
@import '@/assets/scss/md/_colors'
.tgc-header-card
    top: 70px
    height: 100px
    z-index: 1
    padding-left: 40px !important
    background: linear-gradient (60deg, $mainTheme-primary, $mainTheme-primary) !important
    box-shadow: 0 12px 20px -10px rgb(35 197 119 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(35 197 119 / 20%) !important
h1
    font-size: 1.25rem !important
    font-weight: 500
    letter-spacing: 0.0125em !important

.w-88
    width: 88%
</style>